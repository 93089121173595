import { path } from 'ramda';

async function getApiData(url) {
  try {
    const response = await fetch(url);
    const json = await response.json();
    return { data: json};
  } catch (error) {
    return { error };
  }
}

async function loadPage(url) {
      const dataPath = path(['data', 'attributes']);
      const { data, error } = await getApiData(url);
      if (error) {
        return { error };
      }
      return { data: dataPath(data) };
}

export {
  loadPage,
}
