import { genKey, getSectionType } from 'util';
import { map, pipe, tap } from 'ramda';
import Header from 'components/Header'
import Section from 'components/SectionWrapper'
import Carousel from 'components/Carousel';
import Footer from 'components/Footer';

function selectSection(section) {
  switch (section.__component) {
    case 'sections.header':
      return (<Header data={section}/>);
    case 'sections.carousel':
      return (<Carousel data={section} />);
    case 'sections.footer':
      return (<Footer data={section} />);
    default:
      return null;
  }
}

const renderSection = (section) => {
  const sectionType = getSectionType(section);
  const key = genKey(['section', sectionType].join('-'))(section.id);
  const sectionComponent = selectSection(section);
  if (!sectionComponent) {
    return null;
  }

  return (
    <Section key={key} section={section}>
      {sectionComponent}
    </Section>
  );
}

function SectionedPage(props) {
  const { data, error, loading } = props;
  if (loading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    const { sections } = data;
    const sectionComponents = map(renderSection, sections);
    return (
      <div className="pba-app">
        <div className="container main">
          {sectionComponents}
        </div>
      </div>
    );
  }
}

export default SectionedPage;

