import './Back.css';
function Back() {
  return (
    <a className="pba-back" href="/">
      <span class="small">Return to  Popp Brand Advertising site</span>
    </a>
  );

}

export default Back;
