import React, { useContext } from 'react';
import { map } from 'ramda';
import { useSpringCarousel } from 'react-spring-carousel'
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import useWindowDimensions from 'hooks/useWindowDimensions';

import ApiContext from 'providers/Api';
import { classNames, val } from 'util';
import './Carousel.css';
import Cell from './Cell';

const getPalette = val('palette');
const getCells = val('Cells');
const getVisibleCells = val('visible_cells');
const getCaptionsState = val('show_captions');
const getCaptionLocation = val('caption_location');
const getAllowLinks = val('allow_links');

function Carousel(props) {
  const {data} = props;

  const getUrl = useContext(ApiContext);
  const palette = getPalette(data);
  const cellData = getCells(data);
  const showCaptions = getCaptionsState(data);
  const captionLocation = getCaptionLocation(data);
  let visibleCells = getVisibleCells(data);
  const allowLinks = getAllowLinks(data);



  const { width } = useWindowDimensions();

  if (width < 768) {
    visibleCells = 1;
  }

  const cellOptions = { showCaptions, captionLocation, allowLinks, visibleCells };

  const renderCarouselItem = (options) => (item) => {
    const itemImage = val('cell_image.data.attributes.url', item);
    const imgUrl = getUrl(itemImage);
    const link = val('link', item);
    // console.log(item);
    const cellItem = {
      imgUrl: imgUrl,
      link,
    }
    if (showCaptions) {
      cellItem.caption = val('caption_title', item);
      cellItem.subCaption = val('caption_copy', item);
    }
    if (captionLocation) {
      cellItem.captionPosition = captionLocation;
    }
    return {
      key: item.id,
      renderItem: (
        <Cell
        options={options}
        item={cellItem}
        />
      ),
    }
  }

  const classes = ["carousel", `carousel-${palette}`].join(" ");

  const {
    carouselFragment: cells,
    slideToPrevItem,
    slideToNextItem
  } = useSpringCarousel({
    itemsPerSlide: visibleCells,
    withLoop: true,
    items: map(renderCarouselItem(cellOptions), cellData),
  });


  return (
    <div className={classes}>
      <div className="row">
        <div className="column">
          <div className="carousel-nav carousel-nav-left">
            <FaCaretLeft onClick={slideToPrevItem} />
          </div>
          <div className="carousel-nav carousel-nav-right">
            <FaCaretRight onClick={slideToNextItem} />
          </div>
          <div className="carousel-cells">
            {cells}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;

