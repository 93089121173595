import './SectionWrapper.css';

function SectionWrapper(props) {
  const { section, children } = props;
  const { heading, subheading } = section;

  const renderHeader = (title, subtitle) => {
    let titleJSX = title ? <h2>{title}</h2> : null;
    let subtitleJSX = subtitle ? <h3>{subtitle}</h3> : null;
    if (title || subtitle) {
      return (
        <div className="section-header">
          {titleJSX}
          {subtitleJSX}
        </div>
      )
    }
    return null;
  }

  return (
    <div className="section">
      {renderHeader(heading, subheading)}
      <div className="section-content">
        {children}
      </div>
    </div>
  )
}

export default SectionWrapper;

