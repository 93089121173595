import React, { useContext, useState } from 'react';
import { classNames, fullUrl, val } from 'util';

import logo from 'assets/logo-stitch.png';
import bgd from 'assets/bgd-heading.png';

import ApiContext from 'providers/Api';

import './Header.css';

const getGreeting = val('greeting');
const getCurrentConditions = val('current_conditions');
const getBackground = val('header_bgd.data.attributes.url');

export default function Header(props) {
  const { data } = props;
  const getUrl = useContext(ApiContext);

  const greeting = getGreeting(data);
  const currentConditions = getCurrentConditions(data);
  const bgdImage = getBackground(data);
  const imgUrl = getUrl(bgdImage);

  const headerStyle = {
    backgroundImage: 'url(' + imgUrl + ')',
  }

  return (
    <div className="header" style={headerStyle}>
      <div className="row">
        <div className="column">
          <div className="logo">
            <a href="/pages/1">
              <img src={logo} alt="Stitch Logo" />
            </a>
          </div>
        </div>
        <div className="column">
          <div className="header header-greeting">
            <h2 className="greeting">{greeting}</h2>
            <p>{currentConditions}</p>
          </div>
          <div className="header header-intro">
            <h1>welcome to pba</h1>
            <p>We make Advertising.</p>
            <p>We are students of the human <br />condition with insatiable curiosity.</p>
            <p>Experienced craftsmen & artisans, <br />skilled and proven with the tools of our trade.</p>
          </div>
        </div>
      </div>
    </div>
  );
}


