import './CasePage.css';
import logo from 'assets/logo-flat.png';
import { useLoaderData } from 'react-router-dom';
import { path } from 'ramda';
import { urlFor } from 'util';

import Back from 'components/Back';

const getClientLogo = path([
  'data',
  'client_logo',
  'data',
  'attributes',
  'url',
]);

const getMainImage = path([
  'data',
  'main_image',
  'data',
  [0],
  'attributes',
  'url',
]);



function CasePage() {
  const pageData = useLoaderData();
  const clientLogoPath = getClientLogo(pageData);
  const clientLogoUrl = urlFor(pageData.baseUrl, clientLogoPath);
  const mainImagePath = getMainImage(pageData);
  const mainImageUrl = urlFor(pageData.baseUrl, mainImagePath);

  const { title, copy, keywords } = pageData.data;
  console.log(pageData);


  return (
    <div className="pba-case-study">
      <div className="container case-study">
        <div className="row case-study-header">
          <div className="column column-10">
            <img src={logo} alt="logo" />
          </div>
          <div className="column column-60">
            <h2>Case History</h2>
            <h3>{title}</h3>
          </div>
          <div className="column">
            <img src={clientLogoUrl} alt="client logo" />
          </div>
        </div>
        <div className="row">
          <div className="column column-80 column-offset-10">
            <img src={mainImageUrl} alt="case study examples" />
          </div>
        </div>
        <div className="row">
          <div className="column column-80 column-offset-10">
            <p>{pageData.data.copy}</p>
          </div>
        </div>
        <div className="row">
          <div className="column column-80 column-offset-10">
            <span>{keywords}</span>
          </div>
        </div>
        <div className="row">
          <div className="column column-80 column-offset-10">
            <Back />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CasePage;

