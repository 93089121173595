import { always, cond, equals, T } from 'ramda';

function Cell(props) { const { item, options  } = props;
  const { caption, subCaption, imgUrl, link, captionPosition = 'top', title } = item;
  const { allowLinks, visibleCells } = options;

  let cellClass = cond([
    [equals(1), always("carousel-cell cell-1")],
    [equals(3), always("carousel-cell cell-3")],
    [equals(4), always("carousel-cell cell-4")],
    [T, always("cell cell-3")],
  ])(visibleCells);

  const renderCaption = (position) => (caption) => {
    if (caption && position === captionPosition) {
      return (
        <div className="carousel-caption">
          <h5>{caption}</h5>
          <p>{subCaption}</p>
        </div>
      )
    }
  }

  const renderImage = (url, title) => {
    if (allowLinks) {
      return (
        <div className="carousel-image">
          <a href={link}>
            <img src={url} alt={title} />
          </a>
        </div>
      );
    }
    return (
      <div className="carousel-image">
        <img src={url} alt={title} />
      </div>
    );
  }

  return (
    <div className={cellClass}>
    {renderCaption('top')(caption)}
    {renderImage(imgUrl, title)}
    {renderCaption('bottom')(caption)}
    </div>
  )
}

export default Cell;

