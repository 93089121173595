import './App.css';
import { fullUrl as getUrl } from 'util';
import { loadPage } from 'loaders';

import ApiProvider from 'providers/Api';
import {
  useLoaderData,
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import SectionedPage from 'features/SectionedPage';
import RawPage from 'features/RawPage';
import CasePage from 'features/CasePage';

const baseUrl = process.env.REACT_APP_API;
const rootId = process.env.REACT_APP_ROOT_ID || 1;


const router = createBrowserRouter([
  {
    element: <App />,
    path: '/',
    loader: async () => {
      const fullUrl = [baseUrl, `/api/pages/${rootId}?populate=deep,4`].join('');
      const { data, error } = await loadPage(fullUrl);
      return { data, error, baseUrl, fullUrl };
    }
  },
  {
    element: <CasePage />,
    path: '/case-studies/:pageId',
    loader: async ({ params }) => {
      const fullUrl = [baseUrl, `/api/case-study-pages/${params.pageId}?populate=deep,4`].join('');
      const { data, error } = await loadPage(fullUrl);
      return { data, error, baseUrl, fullUrl };
    }
  },
  {
    element: <RawPage />,
    path: '/pages/:pageId',
    loader: async ({ params }) => {
      const fullUrl = [baseUrl, `/api/raw-pages/${params.pageId}?populate=deep,4`].join('');
      const { data, error } = await loadPage(fullUrl);
      return { data, error, baseUrl, fullUrl };
    }
  },
  { path: '*', element: <div>Not Found</div> },

]);


function renderApp(response) {
  const { data, error, loading } = response;
  if (loading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    return (<SectionedPage data={data} />);
  }
}

function App() {
  let response = useLoaderData();
  return (
    <ApiProvider.Provider value={getUrl(baseUrl)}>
      {renderApp(response)}
    </ApiProvider.Provider>
  );
}

export default function AppWithRouter() {
  return (
    <RouterProvider router={router} />
  );
}
