import React, { useContext } from 'react';
import {
  SiInstagram as Instagram,
  SiLinkedin as Linkedin,
  SiPinterest as Pinterest,
  SiVimeo as Vimeo,
  SiTwitter as Twitter,
} from "react-icons/si";

import ApiContext from 'providers/Api';
import { val } from 'util';

import './Footer.css';
function Footer(props) {
  const getUrl = useContext(ApiContext);
  const { data } = props;
  const imageData = val('footer_logo.data', data);
  const imagePath = val('attributes.url', imageData[0]);
  const imageUrl = getUrl(imagePath);

  const { copy, email, phone } = data;

  return (
    <div className="footer">
      <div className="row">
        <div className="column">
          <div className="footer-copy">
            {copy}
          </div>
          <div className="footer-logo">
            <img src={imageUrl} alt="footer-logo" />
          </div>
        </div>
      </div>
      <div className="row footer-contact-bar">
        <div className="column">
          <div className="footer-contact right">
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </div>
        <div className="column-33">
          <ul className="footer-socials">
            <li><a href="https://twitter.com/poppbrand"><Twitter /></a></li>
            <li><a href="https://instagram.com/poppbrand"><Instagram /></a></li>
            <li><a href="https://www.linkedin.com/company/popp-brand-advertising/about/"><Linkedin /></a></li>
            <li><a href="https://pinterest.com/thepopp"><Pinterest /></a></li>
            <li><a href="https://vimeo.com/poppbrand"><Vimeo /></a></li>
          </ul>
        </div>
        <div className="column">
          <div className="footer-contact left">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;

