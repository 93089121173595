import { curry, addIndex, map, path } from 'ramda';

const mapIndexed = addIndex(map);

const classNames = (classes) => {
  return classes.join(" ");
}

const fullUrl = (baseUrl) => (path) => {
  return `${baseUrl}${path}`;
}

const urlFor = (base, path) => {
  return fullUrl(base)(path);
}

const getSectionType = (section) => {
  const { __component: component } = section;
  const sectionType = component.split('.')[1];
  return sectionType;
}

const genKey = (prefix) => (id) => [prefix, id].join("-");

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const noop = () => {};

const val = curry((pathStr, obj) => {
  const pathArray = pathStr.split(".");
  return path(pathArray, obj);
});

export {
  classNames,
  fullUrl,
  urlFor,
  genKey,
  getSectionType,
  getWindowDimensions,
  mapIndexed,
  noop,
  val,
};

